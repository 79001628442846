import React from "react"
import { useAnalytics } from "../../../hooks/useAnalytics"
import { RichText as Wrapper } from "../../RichText/RichText"

export const RichText = ({ richTextContent, content, analytics = {} }) => {
  const {
    trackPromoImpression,
    trackPromoClick,
    VisibilitySensor,
  } = useAnalytics()
  return (
    <VisibilitySensor
      onChange={visible => visible && trackPromoImpression({ ...analytics })}
    >
      <div onClick={() => trackPromoClick({ ...analytics })}>
        <Wrapper>{richTextContent || content}</Wrapper>
      </div>
    </VisibilitySensor>
  )
}
export default RichText
